import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchAll() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.tenantController.findAll())
        .then(response => resolve({
          data: response.data.body,
        }))
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchById(id) {
    const result = await axios.get(endpoints.system.tenantController.findById(id))
    return result.data.body
  },

  // Items
  async fetchItemById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.tenantController.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async findByUserName(userName) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.tenantController.findByUserName(userName))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async getUserTenants(userId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.tenantController.getUserTenants(userId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async getTenantInfo(key) {
    const response = await axios.get(endpoints.system.tenantController.getTenantInfo(key))
    return response.data.body
  },
  async servingTenantMerchantRegisterLink() {
    const result = await axios.get(endpoints.system.tenantController.servingTenantMerchantRegisterLink())
    return result.data.body
  },
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.tenantController.create(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async updateItem(userId, tenantId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.tenantController.updateUserTenant(userId, tenantId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async deleteItemById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.system.tenantController.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  // Self-Tenant Part
  async registerTenant(bodyData) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.selfTenantController.register(), bodyData)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
