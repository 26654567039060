<template>
  <dx-util-popup
    ref="invitationPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-close-button="true"
    :show-title="true"
    :height="'auto'"
    :width="'auto'"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="invitationPopupShown"
    @hidden="invitationPopupHidden"
  >
    <div>
      <div class="p-1 d-flex align-items-center justify-content-between">
        <span class="mr-2">{{ registrationLink }}</span><p-icon name="feather-copy" size="14px" color="warning" role="button" @click.native="onCopy" />
      </div>
      <div>
        <dx-util-validation-group :ref="groupRefKey">
          <dx-util-text-box v-model="email" label="Email" @enterKey="addEmail">
            <dx-util-validator>
              <dx-util-email-rule message="Email is invalid" />
            </dx-util-validator>
              <DxTextBoxButton
                :options="addEmailButton"
                name=""
                location="after"
              />
          </dx-util-text-box>
        </dx-util-validation-group>
      </div>
      <div class="pt-1">
        <dx-util-text-area id="emails" v-model="emails"
        label="Email List"
          :min-height="'80px'" :width="'400px'"
          :auto-resize-enabled="true"
          :read-only="true"
        >
          <dx-util-validator>
            <dx-util-required-rule message="At least one email is required" />
          </dx-util-validator>
        </dx-util-text-area>
      </div>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Send Email" type="success" style="width:100px" @click="send" />
    </div>
  </dx-util-popup>
</template>

<script>
import tenantService from '@/http/requests/system/tenantService'
import emailService from '@/http/requests/system/emailService'
import Notify from '@/@robustshell/utils/notify'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'

export default {
  components: {
    DxTextBoxButton,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popupTitle: '',
      email: '',
      emailList: [],
      emails: '',
      registrationLink: '',
      groupRefKey: 'targetGroup',
      addEmailButton: {
        icon: 'add',
        type: 'default',
        onClick: () => {
          this.addEmail()
        },
      },
    }
  },
  computed: {
    invitationPopup() {
      return this.$refs.invitationPopupRef.instance
    },
    validationGroup() {
      return this.$refs[this.groupRefKey].instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.invitationPopup.show()
      },
    },
  },
  methods: {
    async invitationPopupShown() {
      this.popupTitle = 'Share Invitation Link'
      this.registrationLink = await tenantService.servingTenantMerchantRegisterLink()
      this.onCopy()
    },
    invitationPopupHidden() {
      this.emailList = []
      this.emails = ''
    },
    closePopup() {
      this.invitationPopup.hide()
    },
    async addEmail() {
      const result = await this.validationGroup.validate()
      if (result.isValid) {
        this.emailList.push(this.email)
        const uniqueEmailList = Array.from(new Set(this.emailList))
        this.emails = uniqueEmailList.toString()
        this.email = ''
      }
    },
    send(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        const emailList = this.sanitizeContent(this.emails)
        emailService.sendInviteMerchantRegistrationLink(emailList)
        this.closePopup()
      }
    },
    sanitizeContent(input) {
      return input.replace(/[\s\r\n]+/g, ',')
    },
    async onCopy() {
      await navigator.clipboard.writeText(this.registrationLink)
      Notify.info('Invitation link is copied on clipboard')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
